<template>
  <div
    class="alert alert-dismissible d-flex flex-column flex-sm-row w-100 p-5"
    :class="`bg-light-${type}`"
  >
    <span
      class="svg-icon svg-icon-2hx me-4 mb-5 mb-sm-0"
      :class="`svg-icon-${type}`"
    >
      <inline-svg :src="icon" />
    </span>
    <div class="d-flex flex-column pe-0 pe-sm-10">
      <h4 v-if="title" class="mb-2 fw-bold">
        {{ title }}
      </h4>
      <span v-if="title">
        {{ I18nUtils.translate("sea.alerts.reason") }}
        {{ content }}
      </span>
    </div>
    <button
      type="button"
      class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
      data-bs-dismiss="alert"
    >
      <span class="svg-icon svg-icon-2x" :class="`svg-icon-${type}`">
        <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
      </span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { I18nUtils } from "@/sea/utils/I18nUtils";

export default defineComponent({
  name: "light-alert",
  props: {
    type: String,
    icon: String,
    title: String,
    content: String,
  },
  setup() {
    return {
      I18nUtils,
    };
  },
});
</script>
