import { ref } from "vue";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import ApiService from "@/core/services/ApiService";

export const getListRequest = <Type>() => {
  const listRef = ref<Array<Type> | null | undefined>([]);
  const totalRef = ref(0);
  const pageNumberRef = ref(1);
  const pageSizeRef = ref(10);
  const responseRef = ref<AxiosResponse>();
  const errorRef = ref(null);
  const loadingRef = ref(false);

  const getList = async (
    needAuthHeader = true,
    path: string,
    params?: AxiosRequestConfig
  ) => {
    if (needAuthHeader) {
      ApiService.setHeader();
    }

    loadingRef.value = true;

    try {
      listRef.value = null;
      const result = await ApiService.get(path, params);
      responseRef.value = result;
      listRef.value = result.data && result.data.items ? result.data.items : [];
      totalRef.value = result.data && result.data.total ? result.data.total : 0;
      pageNumberRef.value =
        result.data && result.data.pageNumber ? result.data.pageNumber : 1;
      pageSizeRef.value =
        result.data && result.data.pageSize ? result.data.pageSize : 0;
    } catch (ex) {
      errorRef.value = ex.message;
    } finally {
      loadingRef.value = false;
    }

    loadingRef.value = false;
  };

  return {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    loadingRef,
    getList,
  };
};

export const getDataRequest = <Type>() => {
  const dataRef = ref<Type | null | undefined>(null);
  const responseRef = ref<AxiosResponse>();
  const errorRef = ref(null);
  const loadingRef = ref(false);

  const getData = async (
    needAuthHeader = true,
    path: string,
    params?: AxiosRequestConfig
  ) => {
    if (needAuthHeader) {
      ApiService.setHeader();
    }

    loadingRef.value = true;

    try {
      dataRef.value = null;
      const result = await ApiService.get(path, params);
      responseRef.value = result;
      dataRef.value = result.data;
    } catch (ex) {
      errorRef.value = ex.message;
    } finally {
      loadingRef.value = false;
    }

    loadingRef.value = false;
  };

  return {
    responseRef,
    errorRef,
    dataRef,
    loadingRef,
    getData,
  };
};

export const patchDataRequest = <Type>() => {
  const responseRef = ref<AxiosResponse>();
  const errorRef = ref(null);
  const dataRef = ref<Type | null | undefined>(null);

  const patchData = async (
    needAuthHeader = true,
    path: string,
    data?: unknown,
    params?: AxiosRequestConfig
  ) => {
    if (needAuthHeader) {
      ApiService.setHeader();
    }

    try {
      dataRef.value = null;
      const result = await ApiService.patch(path, data, params);
      responseRef.value = result;
      dataRef.value = result.data;
    } catch (ex) {
      errorRef.value = ex.message;
    }
  };

  return {
    responseRef,
    errorRef,
    dataRef,
    patchData,
  };
};
