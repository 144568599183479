import { getDataRequest, getListRequest } from "@/sea/apis/BaseService";
import { ProductCategoryResponse } from "@/sea/apis/models/productCategory/ProductCategoryResponse";
import { ref } from "vue";

export const getProductCategoriesRequest = () => {
  const {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getList,
  } = getListRequest<ProductCategoryResponse>();
  const productCategoriesRef = ref<
    Array<ProductCategoryResponse> | null | undefined
  >([]);

  const getProductCategories = async (
    name: string | null,
    sort: string | null,
    pageNumber: number,
    pageSize: number
  ) => {
    await getList(true, "/product-categories", {
      params: {
        name: name,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
    productCategoriesRef.value = listRef.value;
  };

  return {
    responseRef,
    errorRef,
    productCategoriesRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getProductCategories,
  };
};

export const getProductCategoryRequest = () => {
  const { responseRef, errorRef, dataRef, getData } =
    getDataRequest<ProductCategoryResponse>();
  const productCategoryRef = ref<ProductCategoryResponse | null | undefined>(
    null
  );

  const getProductCategory = async (productCategoryId: number) => {
    await getData(true, `/product-categories/${productCategoryId}`);
    productCategoryRef.value = dataRef.value;
  };

  return { responseRef, errorRef, productCategoryRef, getProductCategory };
};
